import React from "react";
import Layout from "../components/layout.js";
import Image from "../components/image.js";

const DownloadButtons = ({name, format, variants}) => {
  if (!variants.filter(variant => variant.format === format).length) {
    return <></>
  }
  return <div>
    <h2>{format} Format</h2>
    <ul className="downloads">
    {
      variants
        .filter(variant => variant.format === format)
        .map(variant => {
          const variantInfo = [];
          if (variant.size) {
            variantInfo.push(variant.size+"x"+variant.size);
          }
          if (variant.theme) {
            variantInfo.push(variant.theme);
          }
          if (variantInfo.length === 0) {
            variantInfo.push(format);
          }
          return (<li key={variant.path} data-format={ format }>
            <a href={ "/"+variant.path } download={ true } title={"Download "+name+" in "+variantInfo.join(" - ")}>
              Download <strong>{ variantInfo.join(" ") }</strong>
            </a>
          </li>)
        })
    }
    </ul>
  </div>
}

const Icon = ({pageContext}) => {
  const {
    name,
    iconFile,
    variants,
    provider,
    ancestors
  } = pageContext;
  const formats = [...new Set(variants.map(e => e.format))].join(" or ")
  return <Layout infos={{
    title: name,
    type: "icon",
    description: provider.name +" "+ name +" high quality icon download, available in "+formats,
    ancestors: [
      provider,
      ...ancestors
    ].map((e,i,a) => {
      e.path = a.slice(0,i+1).map(e => e.slug).join("/");
      return e;
    })
  }}>
    <section>
      <h1>{ name }</h1>
      <Image src={ iconFile } alt={ name } />
      <DownloadButtons variants={ variants } name={name} format="PNG" />
      <DownloadButtons variants={ variants } name={name} format="SVG" />
    </section>
  </Layout>
}

export default Icon;
